import React from "react";
import "./About.css";
// import profileimg2 from "../../assets/profile2.png";
import profileimg2 from "../../assets/boy.png";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  AOS.init({
    duration: 1200,
  });

  return (
    <div id="about-section">
      <div className="about-wrapper">
        <div className="about-container">
          <div className="about-header" data-aos="fade-down">
            <h1>ABOUT ME</h1>
          </div>
          <div className="about-content">
            <div className="about-p" data-aos="fade-up">
              <hr className="about-hr"></hr>
              <p>
               As an experienced QA Automation Engineer with a strong foundation in software testing methodologies and automation tools like Cypress, JavaScript, and TypeScript, I have consistently delivered high-quality results. My proficiency extends to a range of technologies, including databases like MongoDB and SQL, as well as diverse programming languages such as .NET, PHP, and C#.

In addition to my QA expertise, I've enjoyed the opportunity to work as a freelance fullstack developer. This experience has allowed me to immerse myself in both frontend and backend development, providing me with a holistic perspective on the software development process. I believe that being a well-rounded developer is the key to creating robust and comprehensive software solutions.

While I excel in my current roles, my passion for development goes beyond testing. I have a keen interest in Solidity development and aspire to transition into a fullstack developer role, combining my QA and development skills to build end-to-end solutions. My dedication to continuous learning and staying current with industry trends has driven me to pursue this transition.

With a detail-oriented mindset and strong problem-solving skills, I approach every project with a commitment to excellence, ensuring meticulous execution. I am dedicated to exceeding expectations and delivering exceptional results in all aspects of my work. My goal is to expand my role from QA to development, utilizing my extensive experience to create software solutions that are both thoroughly tested and expertly crafted.
              </p>
            </div>
            <div className="about-img" data-aos="zoom-out-down">
              <img src={profileimg2} alt="/" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
